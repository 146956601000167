
    import { mixins, Component, Prop } from 'nuxt-property-decorator';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import {
        AvailableGroups,
        AvailableTypes,
        ComponentMeta
    } from '@/components/editor/config/types';
    import WindowMixin from '@/mixins/windowMixin';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    const striptags = require('striptags');

    export const meta: ComponentMeta = {
        title: 'Полезные статьи',
        options: {
            head: {
                type: AvailableTypes.string,
                title: 'Заголовок',
                default:
                    'Полезные статьи: технологии, розбор ошибок, трендовые сувениры...'
            },
            links: {
                type: AvailableTypes.array,
                title: 'Ссылки',
                item: {
                    text: {
                        type: AvailableTypes.editor,
                        title: 'Текст ссылки'
                    },
                    href: {
                        type: AvailableTypes.string,
                        title: 'Адрес ссылки'
                    }
                }
            }
        },
        group: AvailableGroups.Список
    };

    @Component
    export default class UsefulArticles extends mixins(
        PageBlockMixin,
        WindowMixin,
        OptionsFieldsInlineEditor
    ) {
        @Prop() links;
        @Prop() component;
        striptags = striptags;
    }
