import { render, staticRenderFns } from "./TriggerBlockWithSixPicture.vue?vue&type=template&id=13a08578&scoped=true&"
import script from "./TriggerBlockWithSixPicture.vue?vue&type=script&lang=ts&"
export * from "./TriggerBlockWithSixPicture.vue?vue&type=script&lang=ts&"
import style0 from "./TriggerBlockWithSixPicture.vue?vue&type=style&index=0&id=13a08578&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13a08578",
  null
  
)

export default component.exports