
    import { Component, mixins, Prop } from 'nuxt-property-decorator';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import Button from '@/components/blocks/Button.vue';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import FormMixin from '@/mixins/formMixin';
    import Form from '@/components/blocks/Form.vue';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';

    export const meta = {
        title: 'Тригерный блок с формой',
        options: {
            head: {
                type: AvailableTypes.editor,
                title: 'Заголовок',
                default: 'Что ещё сделать?'
            },
            text: {
                type: AvailableTypes.editor,
                title: 'Текст',
                default:
                    'Приехать на фабрику, посмотреть образцы, подобрать материалы, проконсультироваться с технологом'
            },
            img: {
                type: AvailableTypes.image,
                title: 'Картинка',
                default: {
                    src: '/block-default/TriggerBlock/send-contacts.svg'
                }
            },
            form: {
                type: AvailableTypes.component,
                componentName: 'Form'
            }
        },
        group: AvailableGroups.Формы
    };

    @Component({
        components: {
            OptionsFieldsInlineEditor,
            Form,
            ImageComponent,
            Button
        }
    })
    export default class TriggerBlock extends mixins(PageBlockMixin, FormMixin) {
        @Prop() component;
        @Prop() head;
        @Prop() text;
        @Prop() form;
    }
