
    import { Component, mixins, Prop } from 'nuxt-property-decorator';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import Button from '@/components/blocks/Button.vue';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import FormMixin from '@/mixins/formMixin';
    import Form from '@/components/blocks/Form.vue';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';
    import { optionsDefault } from '@/components/editor/config/optionsDefault';

    export const meta = {
        title: 'Тригерный блок с 6-ю картинками',
        options: {
            head: {
                type: AvailableTypes.editor,
                title: 'Заголовок',
                default: 'Вы будете в хорошей компании'
            },
            images: {
                title: 'Картинки',
                type: AvailableTypes.array,
                item: {
                    img: {
                        type: AvailableTypes.image,
                        title: 'Картинка'
                    }
                },
                default: optionsDefault.TriggerBlockWithSixPicture
            },
            form: {
                type: AvailableTypes.component,
                componentName: 'Form'
            }
        },
        group: AvailableGroups.Формы
    };

    @Component({
        components: {
            OptionsFieldsInlineEditor,
            Form,
            ImageComponent,
            Button
        }
    })
    export default class TriggerBlockWithSixPicture extends mixins(
        PageBlockMixin,
        FormMixin
    ) {
        @Prop() component;
        @Prop() images;
        @Prop() head;
        @Prop() form;
    }
