
    import { mixins, Component, Prop } from 'nuxt-property-decorator';
    import PaletteMixin from '@/mixins/paletteMixin';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import Button from '@/components/blocks/Button.vue';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import {
        AvailableGroups,
        AvailableTypes,
        ComponentMeta
    } from '@/components/editor/config/types';
    import Form from '@/components/blocks/Form.vue';

    export const meta: ComponentMeta = {
        title: 'Тригерный блок с двумя картинками и фоном',
        options: {
            head: {
                type: AvailableTypes.editor,
                title: 'Заголовок',
                default: '<p>Узнать цены на сумки</p>'
            },
            text: {
                type: AvailableTypes.editor,
                title: 'Текст',
                default:
                    '<p>Мы не пишем цены так как это слишком громоздко. Каждый наш заказ уникален, в нем много переменных: стоимость материала, индивидуальные размеры, способ брендирования, тираж и сроки.&nbsp;</p>'
            },
            leftImg: {
                type: AvailableTypes.image,
                title: 'Картинка слева',
                default: {
                    src: '/block-default/TriggerBlockImages/bags-size1.png',
                    alt: '',
                    title: ''
                }
            },
            rightImg: {
                type: AvailableTypes.image,
                title: 'Картинка справа',
                default: {
                    src: '/block-default/TriggerBlockImages/bags-size1.png',
                    alt: '',
                    title: ''
                }
            },
            buttonText: {
                type: AvailableTypes.string,
                title: 'Текст кнопки',
                default: 'Заказать расчет по параметрам'
            },
            form: {
                type: AvailableTypes.component,
                componentName: 'Form'
            }
        },
        group: AvailableGroups.Формы
    };

    @Component({
        components: { Form, OptionsFieldsInlineEditor, Button, ImageComponent }
    })
    export default class TriggerBlockImages extends mixins(PageBlockMixin, PaletteMixin) {
        @Prop() buttonText;
        @Prop() leftImg;
        @Prop() rightImg;
        @Prop() component;
        @Prop() form;
    }
