
    import { mixins, Component, Prop } from 'nuxt-property-decorator';
    import PageBlockMixin from '@/mixins/pageBlockMixin';
    import Button from '@/components/blocks/Button.vue';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import PaletteMixin from '@/mixins/paletteMixin';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import {
        AvailableGroups,
        AvailableTypes,
        ComponentMeta
    } from '@/components/editor/config/types';
    import Form from '@/components/blocks/Form.vue';

    export const meta: ComponentMeta = {
        title: 'Тригерный блок с двумя картинками и границей',
        options: {
            type: AvailableTypes.componentOptions,
            componentName: 'TriggerBlockImages'
        },
        group: AvailableGroups.Формы
    };

    @Component({
        components: { Form, OptionsFieldsInlineEditor, ImageComponent, Button }
    })
    export default class TriggerBlockImagesBorder extends mixins(
        PageBlockMixin,
        PaletteMixin
    ) {
        @Prop() buttonText;
        @Prop() leftImg;
        @Prop() rightImg;
        @Prop() form;
        @Prop() component;
    }
